import { Autocomplete, Button, TextField, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme.js';

export const CmpButton = ({
  xbtnName = '',
  xOnClick,
  xVariant = 'contained',
  xDisabled = false,
  xSize = 'small',
  xId = '',
  xSendIcon = '',
  xendIcon = ''
}) => {
  const fTextFieldDummy = (e) => {};
  return (
    <Button
      id={xId}
      onClick={xOnClick ? xOnClick : fTextFieldDummy}
      variant={xVariant}
      disabled={xDisabled}
      size={xSize}
      className="btn-text-transform-none btn-min-width btn-nav-font"
      sx={{
        width: 'auto',
        borderRadius: '20px',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.text,
        textTransform:'capitalize'
      }}
      startIcon={xSendIcon}
      endIcon={xendIcon}>
      {xbtnName}
    </Button>
  );
};

export const CmpButtonError = ({
  xbtnName = '',
  xOnClick,
  xVariant = 'contained',
  xDisabled = false,
  xSize = 'small',
  xId = '',
  xSendIcon = '',
  xendIcon = ''
}) => {
  const fTextFieldDummy = (e) => {};
  return (
    <Button
      id={xId}
      onClick={xOnClick ? xOnClick : fTextFieldDummy}
      variant={xVariant}
      disabled={xDisabled}
      size={xSize}
      className="btn-text-transform-none btn-min-width btn-nav-font"
      sx={{
        width: 'auto',
        borderRadius: '20px',
        justifyContent: 'center',
        backgroundColor: theme.palette.statusClr.red,
        color: theme.palette.primary.text,
        textTransform:'capitalize',
        '&:hover':{
          backgroundColor: theme.palette.statusClr.red,
          color: theme.palette.primary.text,
        }
      }}
      startIcon={xSendIcon}
      endIcon={xendIcon}>
      {xbtnName}
    </Button>
  );
};

export const CmpStyleButton = ({
  xbtnName = '',
  xOnClick,
  xVariant = 'outlined',
  xDisabled = false,
  xSize = 'small',
  xId = '',
  xSendIcon = '',
  xendIcon = ''
}) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const fTextFieldDummy = (e) => {};
  return (
    <Button
      id={xId}
      onClick={xOnClick ? xOnClick : fTextFieldDummy}
      variant={xVariant}
      disabled={xDisabled}
      size={xSize}
      sx={{
        textTransform: 'capitalize',
        fontSize: vMediaQuery ? '12px' : '14px',
        width: { xs: '100%', sm: '90%', md: '90%' }
      }}
      startIcon={xSendIcon}
      endIcon={xendIcon}>
      {xbtnName}
    </Button>
  );
};

export const CmpTypographyField = ({ xcomponent, xText, xVariant = 'h6',xMediaQuery }) => {
  return (
    <div className="txt-overflow-ellipse">
      <Typography variant={xVariant} component={xcomponent} sx={{ fontSize:xMediaQuery?'14.6px':'20px', textDecoration:'underline', fontWeight:xMediaQuery?600:''}}>
        {xText}
      </Typography>
    </div>
  );
};

export const CmpTypography = ({ xcomponent, xText, xVariant = 'body1' }) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  return (
    <div className="txt-overflow-ellipse">
      <Typography variant={xVariant} component={xcomponent}  sx={{ fontSize:vMediaQuery?'12px':'14px',marginBottom:'4px', fontFamily:theme.palette.primary.font}}>
        {xText}
      </Typography>
    </div>
  );
};

export const CmpTypographyCom = ({ xcomponent, xText,className, xVariant = 'body1', style }) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  return (
    <div className="txt-overflow-ellipse">
      <Typography variant={xVariant} className={className} component={xcomponent} style={style} sx={{ fontSize:vMediaQuery? '12px':'14px',marginTop:'4px', fontFamily:theme.palette.primary.font}}>
        {xText}
      </Typography>
    </div>
  );
};

export const CmpTypographyStandard = ({ xcomponent, xText, xVariant = 'body1' }) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  return (
    <div className="txt-overflow-ellipse">
      <Typography variant={xVariant} component={xcomponent}  sx={{ fontSize:vMediaQuery?'12px':'14px', fontFamily:theme.palette.primary.font}}>
        {xText}
      </Typography>
    </div>
  );
};

export const SearchInput = ({ xOptions, getOptionLabel, value, xLabel, xOnChange}) => {
  return (
    <div className="txt-overflow-ellipse">
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={xOptions}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={xOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={xLabel}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </div>
  );
};



