import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useMemo, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import TaskIcon from '@mui/icons-material/Task';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import '../amcFollowUp/amcFollowup.css';

const SideMenus = ({ onClose }) => {
  const navigate = useNavigate();

  const vLoginData = useSelector(
    createSelector(
      (state) => state.user,
      (user) => user
    )
  );

  const selectAmcMenu = createSelector(
    (state) => state.user?.menu || [], // Safely handle undefined state
    (menu) => menu
  );
  const amcMenu = useSelector(selectAmcMenu);

  const isAmcFollowupVisible = amcMenu?.some((item) => item.QuickId === 'AMC002');
  const isAdminOrSuperadmin = vLoginData?.Admin === 'Y' || vLoginData?.Superadmin === 'Y';

  const showAmcFollowupMenu = isAmcFollowupVisible || isAdminOrSuperadmin;

  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
  const [isLeadSubmenuOpen, setIsLeadSubmenuOpen] = useState(false);

  const toggleLeadSubmenu = () => {
    setIsLeadSubmenuOpen((prev) => !prev);
  };

  const toggleProjectMenu = () => {
    setIsProjectMenuOpen((prev) => !prev);
  };

  // handle the project submenus
  const fLead = () => {
    navigate('/project');
    toggleLeadSubmenu();
    setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 200);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fConfirm = () => {
    navigate('/lead-Stage?ID=Stage02');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fS1Payment = () => {
    navigate('/lead-Stage?ID=Stage03');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fInsulation = () => {
    navigate('/lead-Stage?ID=Stage04');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fConfig = () => {
    navigate('/lead-Stage?ID=Stage05');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fDataMigration = () => {
    navigate('/lead-Stage?ID=Stage06');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fTraining = () => {
    navigate('/lead-Stage?ID=Stage07');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fPostTraining = () => {
    navigate('/lead-Stage?ID=Stage08');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fFinalDM = () => {
    navigate('/lead-Stage?ID=Stage09');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fS2Payment = () => {
    navigate('/lead-Stage?ID=Stage10');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fLive = () => {
    navigate('/lead-Stage?ID=Stage11');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fPostSupprot = () => {
    navigate('/lead-Stage?ID=Stage12');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fProjectClose= () => {
    navigate('/lead-Stage?ID=Stage13');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fS3Payment = () => {
    navigate('/lead-Stage?ID=Stage14');
    setTimeout(() => {
      setIsLeadSubmenuOpen(false);
    }, 200);
     setTimeout(() => {
      setIsProjectMenuOpen(false);
    }, 300);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  // handle the sidemenu
  const fHome = () => {
    navigate('/home');
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fMyWork = () => {
    navigate('/myWork');
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fAcitivity = () => {
    navigate('/acitivitySummary');
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fSoftwareManagement = () => {
    navigate('/software-management');
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fHeatMap = () => {
    navigate('/heatMap');
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fClientVisit = () => {
    navigate('/client-visit');
    setTimeout(() => {
      onClose();
    }, 400);
  };
  const fAmcFollowup = () => {
    navigate('/AMC-Followup');
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const projectmenuList = useMemo(
    () => (
      <List>
        <ListItemButton onClick={fLead}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Lead" />
        </ListItemButton>
        <ListItemButton onClick={fConfirm}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Confirmation" />
        </ListItemButton>
        <ListItemButton onClick={fS1Payment}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Stage1 Payment" />
        </ListItemButton>
        <ListItemButton onClick={fInsulation}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Installation" />
        </ListItemButton>
        <ListItemButton onClick={fConfig}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Configuration" />
        </ListItemButton>
        <ListItemButton onClick={fDataMigration}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Data Migration" />
        </ListItemButton>
        <ListItemButton onClick={fTraining}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Training" />
        </ListItemButton>
        <ListItemButton onClick={fPostTraining}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Post Training" />
        </ListItemButton>
        <ListItemButton onClick={fFinalDM}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Final Data Migration" />
        </ListItemButton>
        <ListItemButton onClick={fS2Payment}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Stage2 Payment" />
        </ListItemButton>
        <ListItemButton onClick={fLive}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Live" />
        </ListItemButton>
        <ListItemButton onClick={fPostSupprot}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Post Support" />
        </ListItemButton>
        <ListItemButton onClick={fProjectClose}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Project Close" />
        </ListItemButton>
        <ListItemButton onClick={fS3Payment}>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary="Stage3 Payment" />
        </ListItemButton>
      </List>
    ),
    [navigate]
  );

  return (
    <List className="sidemenu">
      <ListItemButton onClick={fHome}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton onClick={fMyWork}>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary="My Work" />
      </ListItemButton>
      <ListItemButton onClick={toggleProjectMenu}>
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
        <ListItemText primary="Lead Project" />
        {isProjectMenuOpen ? (
          <ExpandLessIcon sx={{ color: '#0000008a' }} />
        ) : (
          <ExpandMoreIcon sx={{ color: '#0000008a' }} />
        )}
      </ListItemButton>
      <Collapse in={isProjectMenuOpen} timeout="auto" unmountOnExit className="submenu">
        <div style={{ marginLeft: '26px', borderLeft: '3px solid #0000008a' }}>
        {projectmenuList}
        </div>
      </Collapse>
      <ListItemButton onClick={fAcitivity}>
        <ListItemIcon>
          <LocalActivityIcon />
        </ListItemIcon>
        <ListItemText primary="Activity" />
      </ListItemButton>
      <ListItemButton onClick={fSoftwareManagement}>
        <ListItemIcon>
          <ManageAccountsIcon />
        </ListItemIcon>
        <ListItemText primary="Enhancement" />
      </ListItemButton>
      <ListItemButton onClick={fHeatMap}>
        <ListItemIcon>
          <PersonPinCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Heat Map" />
      </ListItemButton>
      <ListItemButton onClick={fClientVisit}>
        <ListItemIcon>
          <SupervisedUserCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Client Visit" />
      </ListItemButton>
      {showAmcFollowupMenu && (
        <ListItemButton onClick={fAmcFollowup}>
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
          <ListItemText primary="AMC Followup" />
        </ListItemButton>
      )}
    </List>
  );
};

export default SideMenus;
